<template>
  <v-container>
    <v-card outlined>
      <v-toolbar dense flat
        >Usuarios
        <v-spacer></v-spacer>
        <menuUsuarioComponent
          @agregarUsuario="agregarUsuario = true"
          @agregarCSV="agregarCSV = true"
        />
      </v-toolbar>
      <v-divider></v-divider>

      <simpleTableComponent
        :headers="headers"
        :items="usuariosList"
        :pagina="pagina"
        :paginas="paginas"
        :totalRegistros="regTotal"
        :registrosPagina="regPagina"
        :search="search"
        :loading="loading"
        @changePage="changePage"
        @searchItem="searchItem"
        @modificarCorreo="modificarCorreo"
      />
    </v-card>
    <agregarUsuarioComponent
      v-if="agregarUsuario"
      :mostrar="agregarUsuario"
      @cancelar="agregarUsuario = false"
      @usuarioAgregado="usuarioAgregado"
    />
    <agregarUsuariosCSVComponent
      v-if="agregarCSV"
      :mostrar="agregarCSV"
      @cancelar="agregarCSV = false"
      @usuarioAgregado="usuarioAgregado"
    />

    <modificar-correo
      v-if="modificar.mostrar"
      :mostrar="modificar.mostrar"
      :idUsuario="modificar.idUsuario"
      :correo="modificar.correo"
      @cancelar="modificar.mostrar = false"
      @correoModificado="correoModificado"
    />
  </v-container>
</template>

<script>
import { getUsers } from "./usuarios.service";

import simpleTableComponent from "../mainTemplate/simpleTable.vue";
import menuUsuarioComponent from "./menuUsuarios.vue";
import agregarUsuarioComponent from "./agregarUsuario.vue";
import agregarUsuariosCSVComponent from "./agregarUsuariosCSV.vue";

import ModificarCorreo from "./modificarCorreo.vue";

export default {
  name: "usuariosView",
  components: {
    simpleTableComponent,
    menuUsuarioComponent,
    agregarUsuarioComponent,
    agregarUsuariosCSVComponent,
    ModificarCorreo,
  },
  computed: {
    usuariosList() {
      const usuarios = [];
      let contador = this.pagina * this.regPagina - this.regPagina + 1;
      for (const usuario of this.usuarios) {
        const nuevoUsuario = { numero: contador++, ...usuario };
        usuarios.push(nuevoUsuario);
      }
      return usuarios;
    },
  },
  watch: {
    "$route.query"(query) {
      // console.log(query);
      if (query.page != this.pagina) this.pagina = query.page || 1;

      if (query.search) {
        if (query.search != this.search) this.search = query.search;
      }

      this.cargarUsuarios();
    },
  },
  async mounted() {
    this.pagina = this.$route.query.page || 1;
    if (this.$route.query.search) {
      this.pagina = 1;
      this.search = this.$route.query.search;
    }
    this.cargarUsuarios();
  },
  data: () => ({
    loading: false,
    search: "",
    pagina: 1,
    paginas: 2,
    regTotal: 0,
    regPagina: 50,
    headers: [
      {
        text: "#",
        align: "start",
        sortable: false,
        value: "numero",
      },
      { text: "Código", value: "codigo" },
      { text: "Tipo", value: "tipo" },
      { text: "Nombre", value: "nombre" },
      { text: "Correo", value: "correo" },
    ],
    usuarios: [],
    agregarUsuario: false,
    agregarCSV: false,
    modificar: {
      idUsuario: null,
      correo: null,
      mostrar: false,
    },
  }),
  methods: {
    async cargarUsuarios() {
      this.usuarios = [];
      try {
        this.loading = true;
        const sendData = {
          pagina: this.pagina,
          search: this.search,
        };
        const serverResponse = await getUsers(sendData);
        this.loading = false;
        if (!serverResponse.ok) throw new Error(serverResponse.mensaje);
        this.usuarios = serverResponse.usuarios;
        this.regTotal = serverResponse.totalUsuarios;
        this.paginas = Math.ceil(this.regTotal / this.regPagina);
      } catch (error) {
        this.loading = false;
        this.$notificarError(error);
      }
    },
    changePage(page) {
      if (page != this.pagina) {
        let query = { page };
        if (this.search.length > 0) query.search = this.search;
        this.changeRoute(query);
      }
    },
    searchItem(search) {
      this.search = search;
      let query = { page: 1 };
      if (search.length > 0) query.search = search;
      this.changeRoute(query);
    },
    changeRoute(query) {
      const route = "/main/usuarios";
      this.$router.push({ path: route, query });
    },
    usuarioAgregado() {
      this.cargarUsuarios();
    },
    modificarCorreo(idUsuario) {
      const usuario = this.usuarios.find((x) => x._id == idUsuario);
      this.modificar.idUsuario = idUsuario;
      this.modificar.correo = usuario.correo;
      this.modificar.mostrar = true;
    },
    correoModificado(data) {
      const index = this.usuarios.findIndex((x) => x._id == data.idUsuario);
      this.usuarios[index].correo = data.correo;
      this.modificar.idUsuario = null;
      this.modificar.correo = null;
      this.modificar.mostrar = false;
    },
  },
};
</script>

<style>
.disable-events {
  pointer-events: none;
  opacity: 0.2;
}
</style>