<template>
  <v-dialog v-model="mostrar" fullscreen persistent>
    <v-card>
      <v-toolbar color="primary" dark dense flat
        ><h3>Agregar usuarios mediante CSV</h3>
        <v-spacer></v-spacer>
        <v-btn small outlined :loading="loading" @click="$emit('cancelar')"
          >Cerrar</v-btn
        >
      </v-toolbar>
      <v-container>
        <v-card outlined>
          <v-card-title>
            <v-file-input
              label="Seleccione archivo"
              dense
              truncate-length="50"
              outlined
              :loading="loadingFile"
              :disabled="loadingFile || loading"
              :error-messages="fileError"
              :hint="inputHint"
              persistent-hint
              @change="archivoSeleccionado"
            >
            </v-file-input>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              small
              :class="{ 'disable-events': !puedeAgregar || loading }"
              @click="agregarUsuarios()"
              >Agregar usuarios</v-btn
            >
          </v-card-title>
          <v-divider class="mx-3"></v-divider>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">#</th>
                  <th class="text-left">Código</th>
                  <th class="text-left">Correo</th>
                  <th class="text-left">Nombre</th>
                  <th class="text-left">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(usuario, index) in usuarios" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ usuario.codigo }}</td>
                  <td>{{ usuario.correo }}</td>
                  <td>{{ usuario.nombre }}</td>
                  <td :class="{ 'error-text': usuario.error }">
                    {{ usuario.status }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";

import { agregarUsuarioService, subirCSV } from "./usuarios.service";

export default {
  name: "agregarUsuariosCSV",
  props: ["mostrar"],
  mixins: [validationMixin],
  computed: {
    ...mapGetters(["userAdmin"]),
  },
  data() {
    return {
      loading: false,
      loadingFile: false,
      fileError: null,
      puedeAgregar: false,
      inputHint: '',
      usuarios: [],
    };
  },
  methods: {
    async archivoSeleccionado(file) {
      if (file) {
        this.fileError = null;
        try {
          if (file.type !== "text/csv")
            throw new Error("No se seleccionó un archivo CSV");
          const archivo = new FormData();
          archivo.append("file", file);
          this.loadingFile = true;
          const serverResponse = await subirCSV(archivo);
          this.loadingFile = false;
          if (!serverResponse.ok) this.fileError = serverResponse.mensaje;
          else {
            this.usuarios = serverResponse.usuarios;
            if (this.usuarios.length > 0) this.puedeAgregar = true;
            this.inputHint = `Registros: ${this.usuarios.length}`
          }
        } catch (error) {
          this.loadingFile = false;
          this.fileError = error.message;
        }
      }
    },

    async agregarUsuarios() {
      for (let i = 0; i < this.usuarios.length; i++) {
        this.inputHint = `Procesando: ${i+1}/${this.usuarios.length}`
        const usuario = this.usuarios[i];
        this.loading = true;
        const sendData = {
          codigo: usuario.codigo,
          correo: usuario.correo,
        };
        try {
          const serverResponse = await agregarUsuarioService(sendData);
          if (serverResponse.ok) {
            usuario.nombre = serverResponse.usuario.nombre;
            usuario.status = serverResponse.mensaje;
          } else {
            usuario.status = serverResponse.mensaje;
            usuario.error = true;
          }
          this.usuarios.splice(i, 1, usuario);
          this.loading = false;
        } catch (error) {
          this.usuarios[i].status = error.message;
          this.usuarios[i].error = true;
          this.loading = false;
        }
      }
      this.$emit("usuarioAgregado");
      this.inputHint = `Registros: ${this.usuarios.length}`
    },
  },
};
</script>

<style>
.error-text {
  color: brown;
}
</style>