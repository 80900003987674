<template>
  <div>
    <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
    <v-row justify="end" no-gutters>
      <v-col cols="6">
        <v-text-field
          v-model="searchLocal"
          label="Buscar"
          prepend-icon="mdi-magnify"
          style="padding-right: 20px"
          
          @change="buscarItems"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th
              v-for="(header, index) in headers"
              :key="`header-${index}`"
              class="text-left"
            >
              {{ header.text }}
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <slot name="contenido">
            <tr v-for="(item, index) in items" :key="`row-${index}`">
              <td v-for="(header, index) in headers" :key="`item-${index}`">
                {{ item[header.value] }}
              </td>
              
              <td>
                <menu-item 
                  :items="menuItems"
                  :id="item._id"
                  @menuAction="menuAction"
                />
              </td>

            </tr>
          </slot>
        </tbody>
      </template>
    </v-simple-table>
    <v-divider></v-divider>
    <v-row no-gutters>
      <v-col cols="12">
        <v-toolbar dense flat>
          <v-spacer></v-spacer>
          <span class="text-caption"> Página: </span>
          <v-select
            v-model="paginaLocal"
            :items="paginasSelect"
            dense
            class="pagina-select"
            :disabled="loading"
            @change="changePage"
          />
          <span class="text-caption"
            >{{ rangoRegistros }} / {{ totalRegistros }}</span
          >
          <v-btn
            icon
            color="primary"
            :disabled="disablePrev || loading"
            @click="previousPage()"
            ><v-icon>mdi-chevron-left</v-icon></v-btn
          >
          <v-btn
            icon
            color="primary"
            :disabled="disableNext || loading"
            @click="nextPage()"
            ><v-icon>mdi-chevron-right</v-icon></v-btn
          >
        </v-toolbar>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import menuItem from "../mainTemplate/menuItem.vue"

export default {
  name: "simpleTable",
  props: [
    "headers",
    "items",
    "pagina",
    "paginas",
    "registrosPagina",
    "totalRegistros",
    "loading",
    "search",
  ],
  components: {
    menuItem,
  },
  computed: {
    paginasSelect() {
      const paginas = [];
      for (let i = 1; i <= this.paginas; i++) paginas.push(i);
      return paginas;
    },
    rangoRegistros() {
      let inicio =
        this.paginaLocal * this.registrosPagina - this.registrosPagina + 1;
      let fin = inicio + this.items.length - 1;
      return `${inicio}-${fin}`;
    },
    disablePrev() {
      return this.paginaLocal <= 1;
    },
    disableNext() {
      return this.paginaLocal >= this.paginas;
    },
  },
  watch: {
    pagina(value) {
      this.paginaLocal = parseInt(value);
    },
    search(value){
      this.searchLocal = value;
    }
  },
  data: () => ({
    paginaLocal: 1,
    searchLocal: "",
    menuItems: [
      {text: "Modificar correo", action: "modificarCorreo"},
    ],
  }),
  mounted() {
    this.paginaLocal = parseInt(this.pagina);
    this.searchLocal = this.search;
  },
  methods: {
    changePage() {
      this.$emit("changePage", this.paginaLocal);
    },
    previousPage() {
      this.paginaLocal--;
      this.$emit("changePage", this.paginaLocal);
    },
    nextPage() {
      this.paginaLocal++;
      this.$emit("changePage", this.paginaLocal);
    },
    buscarItems() {
      this.$emit("searchItem", this.searchLocal);
    },
    menuAction(action,id){
      this.$emit(action, id)
    },
  },
};
</script>

<style>
.pagina-select {
  max-width: 50px !important;
  margin-left: 15px;
  margin-right: 15px;
  padding-top: 15px !important;
}
</style>