import { getServerRequest, postServerRequest, putServerRequest, patchServerRequest } from '../../helpers/serverRequest';
import { store } from '../../store/store';

const httpURL = store.getters.httpURL;

export async function getUsers(data) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/usuarios`;
    return await postServerRequest(url, data, config);
}

export async function getUsusarioByCodigo(codigo) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/usuarios/siiau/${codigo}`;
    return await getServerRequest(url, config);
}

export async function agregarUsuarioService(data) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/usuarios/nuevoUsuario`;
    return await putServerRequest(url, data, config);
}

export async function subirCSV(file) {
    const token = store.getters.sessionToken;
    const config = { headers: { token, "Content-Type": "multipart/form-data" } };
    const url = `${httpURL}/usuarios/csv`;
    return await postServerRequest(url, file, config);
}

export async function getUsuarioById(id) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/usuarios/byId/${id}`;
    return await postServerRequest(url, id, config);
}

export async function actualizarCorreoService(data) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/usuarios/correo`;
    return await patchServerRequest(url, data, config);
}
