<template>
  <v-dialog v-model="mostrar" persistent max-width="500px">
    <v-card>
      <v-card-title>
        <span class="headline">Actualizar correo</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                v-model="correoLocal"
                name="SG-NU-Correo"
                label="Correo:"
                :disabled="loading"
                :error-messages="errors.correo"
                @input="validarCorreo()"
                @blur="validarCorreo()"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn
          color="primary"
          outlined
          small
          :disabled="loading"
          @click="$emit('cancelar')"
          >Cancelar</v-btn
        >
        <v-btn
          color="primary"
          dark
          small
          :loading="loading"
          :class="{ 'disable-events': errors.correo.length > 0 }"
          @click="actualizarCorreo()"
          >Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";

import { actualizarCorreoService } from "./usuarios.service";

export default {
  name: "modificarCorreo",
  props: ["mostrar", "idUsuario", "correo"],
  mixins: [validationMixin],
  data: () => ({
    loading: false,
    correoLocal: "",
    errors: {
      correo: [],
    },
  }),
  validations: {
    correoLocal: { required, email },
  },
  created() {
    this.correoLocal = this.correo;
  },
  methods: {
    validarCorreo() {
      this.$v.correoLocal.$touch();
      const errors = [];
      if (this.$v.correoLocal.$dirty) {
        !this.$v.correoLocal.required && errors.push("Campo requerido.");
        !this.$v.correoLocal.email && errors.push("Correo no válido.");
      }
      this.errors.correo = errors;
    },
    async actualizarCorreo() {
      try {
        this.loading = true;
        const sendData = {
          idUsuario: this.idUsuario,
          correo: this.correoLocal,
        };
        const serverResponse = await actualizarCorreoService(sendData);
        this.loading = false;

        this.$validateResponse(serverResponse);

        if (!serverResponse.ok) {
          if (serverResponse.correoError)
            this.correoErrors.push(serverResponse.mensaje);
        } else {
          const emitData = {
            idUsuario: this.idUsuario,
            correo: serverResponse.correo
          };
          this.$emit("correoModificado",emitData);
        }
      } catch (error) {
        this.loading = false;
        this.$notificarError(error);
      }
    },
  },
};
</script>