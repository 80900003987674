<template>
  <v-dialog v-model="mostrar" max-width="500" persistent>
    <v-card>
      <v-toolbar color="primary" dark dense flat><h3>Agregar usuario</h3></v-toolbar>
      <v-container>
        <v-row>
          <v-col cols="8">
            <v-text-field
              v-model="codigo"
              name="SG-codigo"
              label="Código"
              type="text"
              ref="codigoInput"
              :loading="loadingCodigo"
              :disabled="loading || loadingCodigo"
              color="blue-grey"
              outlined
              dense
              :error-messages="codigoErrors"
              @input="validarCodigo()"
              @blur="buscarUsuario()"
            >
            </v-text-field>
          </v-col>
          <v-col cols="4">
            <v-select
              v-model="genero"
              label="Genero"
              :loading="loadingCodigo"
              :disabled="loading || loadingCodigo"
              outlined
              dense
              :items="generos"
            ></v-select>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <v-text-field
              v-model="nombre"
              name="SG-nombre"
              label="Nombre"
              type="text"
              readonly
              color="blue-grey"
              outlined
              dense
            >
            </v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="correo"
              name="SG-correo"
              label="Correo"
              type="text"
              :loading="loading"
              color="blue-grey"
              outlined
              dense
              :error-messages="correoErrors"
              @input="validarCorreo()"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-container>
      <v-divider class="mx-3"></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" small outlined @click="$emit('cancelar')"
          >Cancelar</v-btn
        >
        <v-btn
          color="primary"
          small
          :class="{ 'disable-events': blockAceptar }"
          @click="agregarUsuario()"
          >Agregar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required, minLength, email } from "vuelidate/lib/validators";

import { getUsusarioByCodigo, agregarUsuarioService } from "./usuarios.service";

export default {
  name: "agregarUsuario",
  props: ["mostrar"],
  mixins: [validationMixin],
  computed: {
    ...mapGetters(["userAdmin"]),
    blockAceptar() {
      if (this.loading || this.loadingCodigo) return true;
      if (this.codigoErrors.length > 0) return true;
      if (this.nombre.length == 0) return true;
      if (this.correoErrors.length > 0) return true;
      return false;
    },
  },
  validations: {
    codigo: { required, minLength: minLength(5) },
    correo: { email },
  },
  data() {
    return {
      loading: false,
      loadingCodigo: false,
      codigo: "",
      nombre: "",
      correo: "",
      codigoErrors: [],
      correoErrors: [],
      generos:['F','M'],
      genero:'F'
    };
  },
  methods: {
    validarCodigo() {
      this.$v.codigo.$touch();
      this.codigoErrors = [];
      !this.$v.codigo.required && this.codigoErrors.push("Campo requerido.");
      !this.$v.codigo.minLength &&
        this.codigoErrors.push("Mínimo 5 caracteres.");
    },
    validarCorreo() {
      this.$v.correo.$touch();
      this.correoErrors = [];
      !this.$v.correo.email && this.correoErrors.push("Correo no válido.");
    },
    async buscarUsuario() {
      if (this.codigo.length > 3) {
        try {
          this.loadingCodigo = true;
          const serverResponse = await getUsusarioByCodigo(this.codigo);
          this.loadingCodigo = false;
          this.$validateResponse(serverResponse);
          if (!serverResponse.ok) {
            this.codigoErrors.push(serverResponse.mensaje);
            this.nombre = "";
          } else this.nombre = serverResponse.usuario;
        } catch (error) {
          this.loadingCodigo = false;
          this.$notificarError(error);
        }
      }
    },
    async agregarUsuario() {
      try {
        this.loading = true;
        const sendData = { codigo: this.codigo,correo: this.correo,genero:this.genero};
        const serverResponse = await agregarUsuarioService(sendData);
        this.loading = false;
        this.$validateResponse(serverResponse);
        if (!serverResponse.ok) {
          if (serverResponse.codigoError)
            this.codigoErrors.push(serverResponse.mensaje);
          if (serverResponse.correoError)
            this.correoErrors.push(serverResponse.mensaje);
        } else {
          this.$emit("usuarioAgregado");
          this.codigo = "";
          this.nombre = "";
          this.correo = "";
        }
      } catch (error) {
        this.loading = false;
        this.$notificarError(error);
      }
    },
  },
};
</script>
